import {
  addSearchTypesToConfig,
  CONFIGURED,
  initConfig,
  initField,
  SearchConfig,
  SparseConfig,
} from 'quickstart/lib/search/config'
import * as R from 'rambdax'
import {useMemo, useRef} from 'react'
import {
  clone,
  defaults,
  isThenable,
  logger,
  SearchTypes,
  semiSplit,
  truthy,
} from 'tizra'
import {useBlockContext} from './useBlockContext'
import {usePromises} from './usePromises'
import {useTypeDefs} from './useTypeDefs'

const log = logger('useSearchConfig')

type ValueOf<T> = T[keyof T]

export interface UseSearchConfigReturn {
  config: SearchConfig
  configured: boolean
  defaultParams: {
    [key: string]: any
  }
  searchTypes?: SearchTypes
  status: ValueOf<typeof CONFIGURED>
}

export interface UseSearchConfigOptions {
  doPromises?: boolean
  requireConfigured?: ValueOf<typeof CONFIGURED>
}

// This is only for tests. It's a number like React Query's isFetching()
let promising = 0
export const isPromising = () => promising

/**
 * React hook for getting a full search config given a customer config.
 * This is the equivalent of the old config saga.
 */
const _useSearchConfig = (
  userConfig?: SparseConfig,
  {
    doPromises = typeof window !== 'undefined' && userConfig?.mode !== 'quick',
    requireConfigured = userConfig?.mode === 'quick' ?
      CONFIGURED.INITIALIZED
    : CONFIGURED.TYPES,
  }: UseSearchConfigOptions = {},
): UseSearchConfigReturn => {
  const self = useRef<{
    args?: {doPromises: typeof doPromises; userConfig: typeof userConfig}
    config?: SearchConfig
    quickSearchFields?: SearchConfig['quickSearchFields']
    searchTypes?: any
    status: ValueOf<typeof CONFIGURED>
  }>({status: CONFIGURED.UNCONFIGURED}).current

  log.debug?.({userConfig, doPromises, requireConfigured, self: {...self}})

  // If args changes, this kicks off a new config run.
  // This includes the first call to useSearchConfig.
  const args = clone({doPromises, userConfig})
  let {config, status} = (
    R.equals(args, self.args) ? self
      // Note that initConfig might kick off promises, including prop-values
      // fetches, if doPromises is truthy.
    : (
      {
        config: initConfig(userConfig, doPromises),
        status: CONFIGURED.INITIALIZED,
      }
    )) as Required<Pick<typeof self, 'config' | 'status'>>

  // Fetch quickSearchFields from block context. These are the fields that will
  // be searched across the configured meta-types from the quick search box, and
  // for any metadata-level terms query.
  //
  // We used to avoid this work in browse mode, because there are no terms in
  // browse, but it seems like a pointless bug vector for the setup to differ in
  // that way between modes.
  const {quickSearchFields: unparsedQuickSearchFields} = useBlockContext()
  const quickSearchFields = useMemo(() => {
    const qsf = semiSplit(unparsedQuickSearchFields)
    return qsf.length ? qsf : semiSplit(defaults.quickSearchFields)
  }, [unparsedQuickSearchFields])
  if (!R.equals(config.quickSearchFields, quickSearchFields)) {
    config = {...config, quickSearchFields}
  }

  // Fetch searchTypes from the API. These are the meta-type and field
  // definitions so we can build up the facets and advanced search without
  // explicit config.
  //
  // We used to avoid fetching these in quick mode, but that broke
  // StrategizedSearchLink and also QuickSearch extraParams, because we need the
  // field definitions. It no longer seems worthwhile to try to avoid loading
  // searchTypes because we need them for browse and search anyway.
  const searchTypes = useTypeDefs(searchTypesMetaTypes(config))

  if (searchTypes && (!config.fieldDefs || searchTypes !== self.searchTypes)) {
    if (config.fieldDefs && self.searchTypes) {
      // It should be extremely rare to get a searchTypes update, so let's note
      // when it happens.
      log.log('whoa, updating searchTypes', {
        old: self.searchTypes,
        new: searchTypes,
      })
    }

    // Add field info from search-types.
    // This also kicks off prop-values fetches as necessary.
    config = addSearchTypesToConfig(config, searchTypes, doPromises)
    status = CONFIGURED.TYPES
  }

  // If doPromises, then there may be promises attached to fields for the calls
  // to prop-values. Omit promises that have already resolved (loading isn't
  // true) because we've already integrated their results to config.fields
  const promised = usePromises(
    Object.fromEntries(
      Object.entries(config.fields)
        .filter(([_k, v]) => v.loading)
        .map(([k, v]) => [k, v.promise])
        .filter(([_k, v]) => isThenable(v)),
    ),
  )
  promising = Object.values(promised).filter(
    p =>
      p.status !== 'falsy' &&
      p.status !== 'resolved' &&
      p.status !== 'rejected',
  ).length
  log.debug?.({promising, promised})

  // Integrate any resolved promises
  for (const [name, p] of Object.entries(promised)) {
    if (p.status === 'resolved' && p.result) {
      config = R.assocPath(
        ['fields', name],
        initField({...config.fields[name], ...p.result}, doPromises),
        config,
      )
    }
  }

  if (
    status === CONFIGURED.TYPES &&
    !Object.values(config.fields).find(field => field.loading)
  ) {
    status = CONFIGURED.FIELDS
  }

  Object.assign(self, {
    args,
    config,
    quickSearchFields,
    searchTypes,
    status,
  })

  return {
    config,
    configured: checkConfigured(requireConfigured, status, config),
    defaultParams: useMemo(
      () => R.map(f => f.defaultValue, config.fields),
      [config.fields],
    ),
    searchTypes,
    status,
  }
}

// Make it easier to set requireConfigured without needing to import.
export const useSearchConfig = Object.assign(_useSearchConfig, CONFIGURED)

/**
 * The search-types endpoint returns information for Book, PageRange and PdfPage
 * by default. Augment this list with any additional meta-types configured, so
 * that we can ask for their field info. Anything that doesn't apply will be
 * filtered by addSearchTypesToConfig.
 */
function searchTypesMetaTypes(config: SearchConfig): string[] {
  return [
    ...new Set([
      'Book',
      'PageRange',
      'PdfPage',
      ...Object.values(config.metaTypes).filter(truthy).flat(),
    ]),
  ].sort()
}

/**
 * Check if the configured flag can be set to true.
 */
function checkConfigured(
  requireConfigured: ValueOf<typeof CONFIGURED>,
  status: ValueOf<typeof CONFIGURED>,
  config: SearchConfig,
) {
  if (status < requireConfigured) {
    log.debug?.(`checkConfigured ${status} < ${requireConfigured}`)
    return false
  }
  if (
    requireConfigured >= CONFIGURED.TYPES &&
    status < CONFIGURED.FIELDS &&
    Object.values(config.fields).find(field => field.ensureConfigFields)
  ) {
    log.debug?.('checkConfigured hang tight for ensureConfigFields')
    return false
  }
  return true
}
